// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cennik-tsx": () => import("./../../../src/pages/cennik.tsx" /* webpackChunkName: "component---src-pages-cennik-tsx" */),
  "component---src-pages-galeria-tsx": () => import("./../../../src/pages/galeria.tsx" /* webpackChunkName: "component---src-pages-galeria-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-oznamy-strapi-informative-notice-slug-tsx": () => import("./../../../src/pages/oznamy/{StrapiInformativeNotice.slug}.tsx" /* webpackChunkName: "component---src-pages-oznamy-strapi-informative-notice-slug-tsx" */),
  "component---src-templates-blog-list-template-blog-list-template-tsx": () => import("./../../../src/templates/BlogListTemplate/BlogListTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-list-template-blog-list-template-tsx" */),
  "component---src-templates-blog-template-blog-template-tsx": () => import("./../../../src/templates/BlogTemplate/BlogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-blog-template-tsx" */)
}

